<template>
  <div class="group absolute top-0 left-0 parallel-lines-icon" :style="iconStyle">
    <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4H12V16L15 13L16.4 14.4L11 19.8L5.6 14.4L7 13L10 16V4Z" :fill="color" />
      <path d="M18 4H20V16L23 13L24.4 14.4L19 19.8L13.6 14.4L15 13L18 16V4Z" :fill="color" />
    </svg>
    <span
      class="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-xs text-gray-100 rounded-md absolute left-1/2-translate-x-1/2 translate-y-full opacity-0 m-5 mx-auto"
    >{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: 'ParallelLinesIcon',
  props: {
    color: {
      type: String,
      default: 'black', // Default: a shade of blue
    },
    size: {
      type: Number,
      default: 12, // Default size in pixels
    },
    message: {
      type: String,
      default: 'Parallel Execution', // Default message
    },
  },
  computed: {
    iconStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>

<style scoped>
.parallel-lines-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around; /* Adjust spacing between lines */
  align-items: center;
}

.line {
  width: 100%;
  height: 2px; /* Adjust line thickness as needed */
}
</style>

